import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { Row, Col } from "../components/page-components/grid";
import MD from "gatsby-custom-md";
import SectionTitle from "../components/sectiontitle";
import "../style/photography-singlepage.less";

const components = {
  row: Row,
  col: Col
};

export default function({ data }) {
  return (
    <Layout>
      <SEO
        lang="en"
        title={data.markdownRemark.frontmatter.title}
        description={data.markdownRemark.frontmatter.description}
      />
      <div className="container sectionDiv">
        <div className="section-title">
          <SectionTitle title={data.markdownRemark.frontmatter.title.toUpperCase()} />
        </div>
        <article className="photography-post">
          <div className="content row flex">
            <div className="col s12 videoWrapper">
              <iframe 
                allowfullscreen=""
                frameborder="0"
                title="Ruben Alvarez Show reel"
                src={"//www.youtube.com/embed/" + data.markdownRemark.frontmatter.youtubeiideoid + "?wmode=transparent&amp;autoplay=1&amp;theme=dark&amp;controls=1&amp;autohide=1&amp;loop=0&amp;showinfo=0&amp;rel=0&amp;playlist=false&amp;enablejsapi=0"}
              ></iframe>
            </div>
            <div className="col s12">
              <MD
                components={components}
                htmlAst={data.markdownRemark.htmlAst}
              />
            </div>
          </div>
        </article>
      </div>
    </Layout>
  );
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      htmlAst
      id
      frontmatter {
        title
        description
        youtubeiideoid
      }
    }
  }
`;
